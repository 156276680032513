import React, { useEffect } from "react";
import "./App.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBUAM8uaO3AtMzOEx2JC0_zSsgTXg2hMN4",
  authDomain: "pumpkins-dd77b.firebaseapp.com",
  projectId: "pumpkins-dd77b",
  storageBucket: "pumpkins-dd77b.appspot.com",
  messagingSenderId: "500931650678",
  appId: "1:500931650678:web:ef5c242a5c0c013c1ceb57",
  measurementId: "G-5WM2YDNE18",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_title: "Pumpkins",
      page_path: "/",
    });
  }, []);

  return (
    <div className="container">
      <div className="image-container">
        <div className="shimmer"></div>
        <img
          src="https://i.ibb.co/3CFLhRW/IMG-2754.png"
          alt="Drawing Attention"
          onLoad={(e) => (e.target.previousSibling.style.display = "none")}
        />
      </div>

      <h1>
        Hello <span className="strikethrough">Pumpkin</span>, mam
      </h1>

      <p>
        So the first time I heard these were out, <br />
        you were the first person that came to my mind—wild, right? <br />
        Hold on, wait, wait, phew, breathe in, breathe out… let’s start again.{" "}
        <br />
        Actually, let’s drink some water… breathe in, breathe out, <br />
        ok....lets try again 🧘‍♂️
      </p>

      <h1>
        Hello <span className="strikethrough">Pumpkin</span>, mam
      </h1>

      <p>
        So, I saw these and you were the first person that came to mind— <br />
        wild-, given that we haven’t spoken much since earlier this year, <br />
        after I shared-
        <a rel="noopener" href="https://youtu.be/BK1KtWFVoxU" target="_blank">
          that song :)
        </a>{" "}
        ( in hind sight, that was too early... 😅) , <br />
        but in case you’ve forgotten, allow me to awkwardly reintroduce <br />{" "}
        myself all over again.
      </p>

      <p>
        Hi, I’m Tanaka (Craig), and drinking water <br />
        tricks my brain into thinking that I can sing. <br />
        And growing up, I thought I was going to become a pilot 🐻‍❄️ <br />
        I’m a very disappointed Man United fan 😂, <br />
        but I have a soft spot for Tottenham Hotspur too. <br />
        Oh... I do my own chores too, so I can sweep you off your feet <br></br>{" "}
        (hahaha you walked into that one 😝 )… <br />
        Look at me over-sharing already—let me stop. 😊
      </p>

      <p>
        Now that you know my life story you are welcome <br /> to call me besty
        (haha), I hope this makes everything more palatable. <br />
        I hope you love these. <br />
        After doing some research, <br />
        the evidence was overwhelming that you would vibe with these (lol).
      </p>

      <p>
        By the way, the image above is my attempt at drawing your attention.{" "}
        <br />
        Problem is, I’m left-handed, so don’t judge me—I’m no artist! 😅
      </p>

      <p>Anyway, Happy birthday, mam! 🎉</p>

      <p>
        I think you are very special, <br />
        and special people deserve special things. 😊
      </p>

      <p>I’d love to:</p>

      <ul>
        <li>Write to you often 📝</li>
        <li>Go out for lunch on Fridays 🍽️</li>
        <li>Watch live games / sports, concerts, shows 🐥</li>
        <li>Stare into your eyes while you vent about your day 👀</li>
        <li>Pick you up if you’re ever stuck in the rain ☔</li>
        <li>Grab drive through meals late at night 🍗</li>
        <li>Watch movies late 🎬</li>
        <li>
          Make a meal together for lunch on Sundays <br /> (I will cut the
          onions, so that you dont have to cry :wink :wink) 🍳
        </li>
        <li>Go and watch the sunset along the coast 🌅</li>
        <li>Drive Freddy to 'nobody knows' 🚗</li>
        <li>Build and destroy Lego sets 🧱</li>
        <li>Maybe give you a slow massage after a long day 💆‍♀️</li>
      </ul>

      <p>
        travel the wo....…pause 🚶🏽‍♂️ ..…this is really starting to sound more like
        what lovers do, <br />
        so I’ll stop here… unless… unless you want me to continue. 🫠
      </p>

      <p>
        On a mellow note, life is like porridge... <br />
        And unfortunately, we have no control over who likes us, <br />
        but we can control who we like. <br />
        If we do not tell them, then how will they know? <br />I believe that if
        you like someone, you should be intentional about it, <br />
        but also mature enough to handle the outcome.
      </p>

      <p>
        I can sleep peacefully knowing that you know, <br />
        and you, on the other hand, can walk with a bounce, <br />
        knowing that someone sees beneath your beautiful.
      </p>

      <p>
        See these words as a jacket, I hope they keep you warm. <br />
        If not today, then it’s probably because we are in summer, <br />
        so store them somewhere for when winter comes.
      </p>

      <p className="signature">
        Take care. <br />
        Also, I kinda wish they taught people how to wrap gifts in high school.{" "}
        <br />
        Good luck with the{" "}
        <a
          href="https://youtu.be/89s9z_IugG0?list=PL_syaeh9V7BuiOvyxZRw6sJ5lsQVMnT50"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          setup
        </a>
        . 😅
        <br />I promise to do this in person next time 😊
      </p>

      <p className="signature">
        <a
          rel="noopener"
          href="https://www.instagram.com/i_am_tanaka_craig/"
          target="_blank"
        >
          @i_am_tanaka_craig
        </a>
      </p>
    </div>
  );
}

export default App;
